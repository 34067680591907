@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'flowbite/dist/flowbite.css';




.btn{
    @apply rounded-full py-2 px-3 uppercase text-xs font-bold cursor-pointer tracking-wider border-2
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Styling that applies to all pages */
#outlet{
  min-height: 100vh;
}

/* Styling for footer */
#footer{
  bottom: 0;
}